import React from 'react';
import { Link, graphql } from 'gatsby';
import { Layout, Article, Wrapper, Header, Content, Pagination } from '../components';
import Helmet from 'react-helmet';
import config from '../../config/SiteConfig';
import Data from '../models/Data';
import { Edges } from '../models/AllMarkdownRemark';
import { BMCButton } from '../components/BMCButton';

interface Props {
  data: Data;
  pageContext: {
    currentPage: number;
    totalPages: number;
    type: string;
  };
}

const BlogPage = ({ pageContext, data }: Props) => {
  const { currentPage, totalPages, type } = pageContext;
  const { edges } = data.allMarkdownRemark;
  return (
    <Layout>
      <Helmet title={`Blog | ${config.siteTitle}`} />
      <Header>
        <Link to="/">{config.siteTitle}</Link>
      </Header>
      <Wrapper>
        <Content>
          {edges.map((post: Edges) => (
            <Article
              title={post.node.frontmatter.title}
              date={post.node.frontmatter.date}
              excerpt={post.node.excerpt}
              timeToRead={post.node.timeToRead}
              slug={post.node.fields.slug}
              key={post.node.fields.slug}
              type={post.node.frontmatter.type}
            />
          ))}
          <Pagination currentPage={currentPage} totalPages={totalPages} url={type} />
        </Content>
      </Wrapper>
      <BMCButton />
    </Layout>
  );
};

export default BlogPage;

export const BlogQuery = graphql`
  query($skip: Int!, $limit: Int!, $type: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: $type } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MM-DD-YYYY")
            type
          }
          excerpt(pruneLength: 200)
          timeToRead
        }
      }
    }
  }
`;
